<template>
  <BrandTest
    id="divYapKazanQuizWrapper"
    v-if="taskUserId && testId"
    :taskUserId="taskUserId"
    :testId="testId"
  ></BrandTest>
</template>
<script>
import StorageHelper from '@/utils/storageHelper';
import BrandTest from '@/components/brand/BrandTest/BrandTest.vue';
export default {
  name: 'PlayAndWinDetail',
  components: {
    BrandTest,
  },
  data() {
    return {
      taskUserId: null,
      testId: null,
    };
  },
  created() {
    const takeQuizDetail = new StorageHelper({
      id: process.env.VUE_APP_STORAGE_TASK_TAKE_QUIZ_KEY,
    }).get();
    this.taskUserId = takeQuizDetail.taskUserId;
    this.testId = takeQuizDetail.testId;
  },
};
</script>
